a {
  text-decoration: none;
  cursor: pointer;
  color: white;
}
#root {
  height: 100%;
}

@font-face {
  font-family: robotoLight;
  src: url(../src/assets/fonts/Roboto/Roboto-Light.ttf);
}

@font-face {
  font-family: robotoBold;
  src: url(../src/assets/fonts/Roboto/Roboto-Bold.ttf);
}

@font-face {
  font-family: antonRegular;
  src: url(../src/assets/fonts/Epilogue/static/Epilogue-Regular.ttf);
}

.row {
  display: flex;
}

.mt-1 {
  margin-top: 1rem;
}

.mb-1 {
  margin-bottom: 1rem;
}
ul {
  padding: 0;
}

.App {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

* {
  box-sizing: border-box;
}

.bold {
  font-weight: bold;
  font-family: robotoBold, Arial, Helvetica, sans-serif;
}

html,
body {
  font-family: robotoLight, Arial, Helvetica, sans-serif;
  color: black;
  font-weight: 300;
  margin: 0;
  height: 100%;
  box-sizing: border-box;
}

img {
  width: 100%;
  max-width: 100%;
}

body {
  background-color: #1f2122;
}

a {
  text-decoration: none;
  color: #0dca7b !important;
}

button {
  background-color: transparent;
  border: transparent;
}

.hightlight {
  font-weight: bold;
  font-family: robotoBold, Arial, Helvetica, sans-serif;
  // color: rgb(13, 202, 123);
  text-decoration: underline;
}

.set-layout {
  color: white;
  align-items: center;
  justify-content: center;
  height: 100%;

  display: flex;
  width: 100%;
  margin: auto;
  flex-direction: column;

  position: relative;
}

h1 {
  font-family: Neuemontreal, sans-serif;
  color: #fff;
  font-size: 7vw;
  line-height: 78px;
  font-weight: 700;
  text-align: center;
}
@media (min-width: 320px) {
  h1 {
    font-size: 7vw;
  }
  .set-layout {
    top: 4rem;
    padding-left: 0rem;
  }
}
@media (min-width: 768px) {
  h1 {
    font-size: 4vw;
  }
  .set-layout {
    top: 0rem;
    //overflow: hidden;
  }
}

.ml-1 {
  margin-left: 0.5rem;
}

.mr-1 {
  margin-right: 1rem;
}

.d-flex {
  display: flex;
}
.icon-smiley {
  width: 2rem;
  height: 2rem;
  margin-right: 0.5rem;
}

p {
  margin: 0;
  text-align: left;
  overflow: hidden;
  font-weight: 100;
  line-height: 1.9;
  letter-spacing: 0.05rem;
  span {
    opacity: 1;
    animation: reveal 0.9s, cubic-bezier(0.77, 0, 0.175, 1) 0.5s;
  }
}

h1 {
  margin: 0;
  text-align: left;

  font-weight: bold;
  overflow: hidden;

  line-height: 1.3;
  span {
    display: block;
    opacity: 1;
    line-height: 1.3;
    animation: reveal 0.7s, cubic-bezier(0.77, 0, 0.175, 1) 0.5s;
  }
}

h3 {
  margin: 0;
  text-align: left;
  overflow: hidden;
  line-height: 1.3;
  span {
    display: block;
    opacity: 1;
    line-height: 1.3;
    animation: reveal 0.9s, cubic-bezier(0.77, 0, 0.175, 1) 0.5s;
  }
}

.dropdown {
  position: fixed;
  right: 1rem;
  z-index: 55555555;
  bottom: 1rem;

  select {
    width: 4rem;
    padding: 0.5rem;
    background-color: transparent;
    border: 0;
    color: white;
  }
}

@import "@splidejs/react-splide/css";
