/* Base layout */
.layout-indexPage {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  .layout-content {
    display: flex;
    align-items: center;
    color: white;
    width: 100%;
    height: 100%;

    /* Content column */
    .col-content {
      height: 100vh;
      display: flex;
      justify-content: center;

      .infos-index {
        h3 {
          color: #8f8f8f;
        }

        .underline-title {
          align-items: center;
          .icon-hand {
            font-size: 2rem;
          }
        }
      }
    }

    /* Logo column */
    .col-logo {
      height: 100vh;
      display: flex;
      align-items: center;
      justify-content: center;

      .img-index {
        display: flex;
        justify-content: center;

        img {
          max-width: 377px;
          margin: auto;
          animation: float 6s ease-in-out infinite;
        }
      }
    }
  }
}

/* Media Queries */
@media (min-width: 320px) {
  .layout-indexPage .layout-content .col-content {
    width: 100%;
    background-color: #101011;
    align-items: center;

    .infos-index {
      width: 80%;
    }
  }

  .layout-indexPage .layout-content .col-logo {
    width: 100%;
    display: none;
  }

  .layout-indexPage .layout-content .img-index {
    display: none;
  }
}

@media (min-width: 768px) {
  .layout-indexPage {
    top: 0rem;
  }

  .layout-indexPage .layout-content .col-content {
    width: 50%;

    .infos-index {
      width: 60%;
    }
  }

  .layout-indexPage .layout-content .col-logo {
    display: flex;
    width: 50%;

    .img-index {
      width: 60%;
    }
  }
}

/* Keyframes */
@keyframes float {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0px);
  }
}
