/* Base layout Background */
.set-layout {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  .layout-content {
    display: flex;
    align-items: center;
    color: white;
    width: 100%;
    height: 100%;

    /* Content column */
    .col-content {
      height: 100vh;
      display: flex;
      justify-content: center;
    }

    /* Logo column */
    .col-logo {
      height: 100vh;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

/* Base layout Page */
.set-portfolio {
  position: absolute;
  top: 0;
  width: 80%;

  h1 {
    font-family: Lato, sans-serif;
    color: #fff;
    // line-height: 78px;
    font-weight: 700;
    text-align: left;
  }

  .gallery {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-top: 1rem;

    .projet-image {
      display: flex;
      max-width: 344px;
      overflow: hidden;
      color: white;
      height: 100%;
      width: 100%;
      max-height: 167px;
      margin: 0 1rem 1rem 0;

      .link-itemPortfolio {
        position: relative;
        overflow: hidden;
        color: white;

        img {
          width: 100%;
        }

        .content-short {
          position: absolute;
          display: flex;
          background-color: #04a099;
          width: 100%;
          height: 100%;
          opacity: 0;
          align-items: center;
          justify-content: center;
          transition: 0.3s;
          flex-direction: column;

          span {
            color: white;
          }

          .projet-skills {
            display: flex;
            font-size: 0.7rem;

            span {
              // display: block;
              padding: 0.4rem;
              font-size: 0.8rem;
              font-weight: bold;
              background-color: #939191;
              margin: 0.5rem;
              border-radius: 15px;
            }
          }
        }
      }

      .link-itemPortfolio:hover .content-short {
        display: flex;
        opacity: 1;
      }
    }
  }

  .projet-content {
    background-color: #181818;
    padding: 2rem;
  }

  .footer-projetContent {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 2rem;

    .project-tech-list {
      display: flex;
      list-style: none;

      li {
        margin-right: 0.5rem;
      }
    }
  }

  .btn-learnMore {
    padding: 1rem;
    border: 1px solid #eee;
    border-radius: 11px;
    cursor: pointer;

    a {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: white;

      svg {
        margin-left: 0.5rem;
      }
    }
  }
}

.link-portfolio:hover {
  color: #13c455 !important;
  background-color: #181818;
  transition: 0.3s;
}

@media (min-width: 320px) {
  .set-portfolio {
    margin: auto;
    flex-direction: column;
    position: absolute;
    top: 0rem;

    .item-portfolio {
      height: 100%;
      align-items: center;
      flex-direction: column;
      margin: 1rem 0 1rem 0;

      .projet-image {
        display: none;
      }

      .projet-content {
        a:hover {
          color: #13c455 !important;
          background-color: #181818;
        }

        .footer-projetContent {
          display: block;
        }
      }
    }
  }
}

@media (min-width: 768px) {
  /* Base layout Background */
  .set-layout .layout-content .col-content {
    background-color: #101011;
    width: 50%;

    .infos-index {
      width: 60%;
    }
  }

  .set-layout .layout-content .col-logo {
    display: flex;
    width: 50%;

    .img-index {
      width: 60%;
    }
  }

  /* Base layout Layout */
  .set-portfolio {
    margin: auto;
    flex-direction: column;
    top: 4rem;
    max-width: 80%;
    .item-portfolio {
      height: 100vh;
      display: flex;
      align-items: center;
      flex-direction: row;

      .projet-content {
        padding: 1%;

        .footer-projetContent {
          display: flex;
        }
      }
    }
  }
}
