// Base styles
.skills-link:after {
  content: "|";
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

// Keyframes
@keyframes reveal {
  0% {
    transform: translate(0, 100%);
  }
  100% {
    transform: translate(0, 0);
  }
}

// Carousel styles
.alice-carousel__dots {
  display: none;
}

.alice-carousel__prev-btn-item,
.alice-carousel__next-btn-item {
  display: inline-block;
  cursor: pointer;
  padding: 5px;
  margin: 0;
  color: #ffffff;
  font-weight: bold;
}

// Media Queries
@media (min-width: 320px) {
  .layout-AboutPage {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    color: white;

    .layout-content {
      display: flex;
      justify-content: space-between;
      margin: auto;
      align-items: center;
      flex-direction: column;
      position: absolute;
      top: 4rem;
      width: 80%;

      .item-intro {
        width: 90%;
        margin-bottom: 2rem;
      }

      .item-development {
        width: 250px;
        margin: auto;

        h3 {
          text-align: center;
        }
      }
    }
  }
}

@media (min-width: 768px) {
  .layout-AboutPage {
    .layout-content {
      width: 100%;
      height: 100%;
      flex-direction: row;
      top: auto;

      .col-content {
        width: 50%;
        background-color: #101011;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;

        .item-intro {
          width: 65%;
        }
      }

      .col-logo {
        display: flex;
        width: 50%;
        height: 100%;

        .img-index {
          width: 60%;
        }
      }
    }
  }
}

.strong {
  position: relative;
}

.strong::before {
  /* Highlight color */
  background-color: #46b96c;

  content: "";
  position: absolute;
  width: calc(100% + 4px);
  height: 60%;
  left: -2px;
  bottom: 0;
  z-index: 0;
  transform: rotate(-2deg);
}

.hobby-link {
  cursor: pointer;
}

.skills-link {
  cursor: pointer;
}
