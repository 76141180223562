.set-portfolio-details {
  position: absolute;
  top: 0;

  margin-top: 0;

  .projet-content {
    padding: 1%;
  }

  .skills-set {
    margin: 1rem 0;
    display: flex;
  }

  .skill-item {
    padding: 0.5rem;
    border: 1px solid #0fb16e;
    margin-right: 0.5rem;
    border-radius: 1rem;
    display: flex;
    align-items: center;
    flex-direction: row;
  }

  p {
    margin: 0 0 1.5rem 0;
    font-size: 0.9rem;
    text-align: left;
    overflow: hidden;

    span {
      display: block;
      opacity: 1;
    }
  }

  img:hover,
  button:hover {
    cursor: pointer;
  }

  .project-overline {
    margin-top: 1rem;
  }

  .btn-back:hover {
    color: #13c455 !important;
    background-color: #181818;
    transition: 0.3s;
  }

  .gallery {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-top: 1rem;
    button {
      display: inline-block;
      overflow: hidden;
      height: 100%;
      img {
        width: 100%;
        margin-bottom: 1rem;
      }
    }
  }
}

@media (min-width: 320px) {
  .set-portfolio-details {
    width: 80%;
    button {
      display: inline-block;
      width: 100%;
      //max-height: 400px;
      overflow: hidden;
      height: 100%;
      margin-bottom: 1rem;
      img {
        width: 100%;
        margin-bottom: 1rem;
      }
    }

    button:hover img {
      cursor: pointer;
    }
  }
}
@media (min-width: 768px) {
  .set-portfolio-details {
    padding: 4rem 8rem;
    button {
      width: 50%;
      //  max-height: 250px;

      img {
        width: 100%;
        margin-bottom: 1rem;
      }
    }
  }
}
