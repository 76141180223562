/* Base layout */
.layout-contactPage {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  .layout-content {
    display: flex;
    align-items: center;
    color: white;
    width: 100%;
    height: 100%;

    /* Content column */
    .col-content {
      height: 100vh;
      display: flex;
      justify-content: center;

      .infos-index {
        h3 {
          color: #8f8f8f;
        }

        .underline-title {
          align-items: center;
          .icon-hand {
            font-size: 2rem;
          }
        }
      }
    }

    /* Form column */
    .col-form {
      .container-form {
        max-width: 70%;
        margin: auto;
        form {
          // max-width: 250px;
          margin-top: 2rem;
          width: 100%;
          div {
            display: flex;
            flex-direction: column;
            justify-content: left;
            // margin-top: 1rem;
          }
          button {
            color: white;
            margin-top: 1rem;
          }
        }
      }
    }
  }
  /* Keyframes */
  @keyframes float {
    0% {
      transform: translateY(0px);
    }
    50% {
      transform: translateY(-20px);
    }
    100% {
      transform: translateY(0px);
    }
  }

  .group {
    position: relative;
    margin-bottom: 2rem;
  }
  input,
  textarea {
    font-size: 0.9rem;
    font-family: Arial, Helvetica, sans-serif;
    color: #fff;
    padding: 10px 10px 10px 5px;
    display: block;
    border: none;
    border-bottom: 2px solid #757575;
    background-color: #1f2122;
    z-index: 444444444444;
  }

  input:focus {
    outline: none;
  }

  textarea:focus {
    border-bottom: #21e065;
  }

  /* LABEL ======================================= */
  label {
    color: #999;
    font-size: 18px;
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: 5px;
    top: 10px;
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
  }

  /* active state */
  input:focus ~ label,
  input:valid ~ label {
    top: -20px;
    font-size: 14px;
    color: #1ed760;
  }

  /* BOTTOM BARS ================================= */
  .bar {
    position: relative;
    display: block;
    // width: 300px;
  }
  .bar:before,
  .bar:after {
    content: "";
    height: 2px;
    width: 0;
    bottom: 1px;
    position: absolute;
    background: #1ed760;
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
  }
  .bar:before {
    left: 50%;
  }
  .bar:after {
    right: 50%;
  }

  /* active state */
  input:focus ~ .bar:before,
  input:focus ~ .bar:after {
    width: 50%;
  }

  /* HIGHLIGHTER ================================== */
  .highlight {
    position: absolute;
    height: 60%;
    width: 100px;
    top: 25%;
    left: 0;
    pointer-events: none;
    opacity: 0.5;
  }

  /* active state */
  input:focus ~ .highlight {
    -webkit-animation: inputHighlighter 0.3s ease;
    -moz-animation: inputHighlighter 0.3s ease;
    animation: inputHighlighter 0.3s ease;
  }

  /* ANIMATIONS ================ */
  @-webkit-keyframes inputHighlighter {
    from {
      background: #1ed760;
    }
    to {
      width: 0;
      background: transparent;
    }
  }
  @-moz-keyframes inputHighlighter {
    from {
      background: #1ed760;
    }
    to {
      width: 0;
      background: transparent;
    }
  }
  @keyframes inputHighlighter {
    from {
      background: #1ed760;
    }
    to {
      width: 0;
      background: transparent;
    }
  }

  button {
    display: inline-block;
    outline: none;
    cursor: pointer;
    font-size: 1rem;
    line-height: 1;
    border-radius: 500px;
    transition-property: background-color, border-color, color, box-shadow,
      filter;
    transition-duration: 0.3s;
    border: 1px solid transparent;
    letter-spacing: 2px;

    text-transform: uppercase;
    white-space: normal;
    font-weight: 700;
    text-align: center;
    padding: 1rem 2rem;
    color: #fff;
    background-color: #1ed760;

    :hover {
      transform: scale(1.04);
      background-color: #21e065;
    }
  }
}

/* Media Queries */
@media (min-width: 320px) {
  .layout-contactPage .layout-content .col-content {
    width: 100%;
    background-color: #101011;
    align-items: center;
    display: none;
  }

  .layout-contactPage .layout-content .col-form {
    width: 100%;
  }

  .layout-contactPage .layout-content .img-index {
    display: none;
  }
}

@media (min-width: 768px) {
  .layout-contactPage {
    top: 0rem;
  }

  .layout-contactPage .layout-content .col-content {
    width: 50%;
    display: flex;
    .infos-index {
      width: 60%;
    }
  }

  .layout-contactPage .layout-content .col-form {
    display: flex;
    width: 50%;

    .img-index {
      width: 60%;
    }
  }
}
