@media (min-width: 320px) {
  .nav-bar {
    background: #181818;
    width: 100%;
    height: 60px;
    position: fixed;
    top: 0;
    z-index: 3;
    min-height: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .logo {
      display: block;
      padding: 8px 0;
      left: 0;
      right: 0;
      margin: auto auto auto 15px;

      img {
        display: block;
        margin: 8px auto;
        width: 30px;
        height: auto;

        &.sub-logo {
          width: 50px;
        }
      }
    }

    nav {
      display: flex;
      text-align: center;
      position: absolute;
      height: auto;
      top: auto;
      width: 100%;
      align-items: center;
      margin: auto;
      justify-content: center;

      a {
        font-size: 22px;
        color: #4d4d4e;
        display: block;
        line-height: 51px;
        height: 51px;
        position: relative;
        text-decoration: none;
        margin: 0 5px 0 5px;

        i {
          transition: all 0.3s ease-out;
        }

        &:hover {
          color: #0dca7b;

          svg {
            opacity: 0;
          }

          &:after {
            opacity: 1;
          }
        }

        &:after {
          // content: "";
          font-size: 9px;
          letter-spacing: 2px;
          position: absolute;
          bottom: 0;
          display: block;
          width: 100%;
          text-align: center;
          opacity: 0;
          -webkit-transition: all 0.3s ease-out;
          transition: all 0.3s ease-out;
        }

        &:first-child {
          &:after {
            // content: "ACCUEIL";
            // content: attr(data-translated-content);
          }
        }
      }

      a.about-link {
        &:after {
          // content: "À PROPOS";
        }
      }

      a.contact-link {
        &:after {
          // content: "CONTACT";
        }
      }

      a.skills-link {
        &:after {
          // content: "PROJETS";
        }
      }

      a.active {
        svg {
          color: #0dca7b;
        }
      }
    }

    ul {
      display: flex;

      li {
        list-style: none;
        a {
          padding: 7px 0;
          display: block;
          font-size: 1.4rem;
          line-height: 16px;
          color: #4d4d4e;
          margin-right: 0.5rem;

          &:hover {
            color: #ffd700;
          }
        }
      }
    }
  }

  .random {
    color: #b9b9b9;
  }
}

@media (min-width: 768px) {
  .nav-bar {
    background: #181818;
    width: 60px;
    height: 100%;
    position: fixed;
    top: 0;
    z-index: 3;
    min-height: 500px;

    .logo {
      display: block;
      padding: 8px 0;
      position: absolute;
      top: 0;
      margin: auto;

      img {
        display: block;
        margin: 8px auto;
        width: 30px;
        height: auto;

        &.sub-logo {
          width: 50px;
        }
      }
    }

    nav {
      display: block;
      text-align: center;
      position: absolute;
      height: 210px;
      top: 50%;
      margin-top: -120px;
      width: 100%;

      a {
        font-size: 22px;
        color: #4d4d4e;
        display: block;
        line-height: 51px;
        height: 51px;
        position: relative;
        text-decoration: none;

        i {
          transition: all 0.3s ease-out;
        }

        &:hover {
          color: #0dca7b;

          svg {
            opacity: 0;
          }

          &:after {
            opacity: 1;
          }
        }

        &:after {
          line-height: 1rem;
          // content: "";
          font-size: 9px;
          letter-spacing: 2px;
          position: absolute;
          bottom: 0;
          display: block;
          width: 100%;
          text-align: center;
          opacity: 0;
          -webkit-transition: all 0.3s ease-out;
          transition: all 0.3s ease-out;
        }

        &:first-child {
          &:after {
            // content: attr(data-translated-content);
            // content: "ACCUEIL";
          }
        }
      }

      a.about-link {
        &:after {
          // content: "À PROPOS";
        }
      }

      a.contact-link {
        &:after {
          content: "CONTACT";
        }
      }

      a.skills-link {
        &:after {
          // content: "PROJETS";
        }
      }

      a.active {
        svg {
          color: #0dca7b;
        }
      }
    }

    ul {
      position: absolute;
      bottom: 20px;
      width: 100%;
      display: block;
      padding: 0;
      list-style: none;
      text-align: center;
      margin: 0;

      li {
        a {
          padding: 7px 0;
          display: block;
          font-size: 15px;
          line-height: 16px;
          color: #4d4d4e;

          &:hover {
            color: #ffd700;
          }
        }
      }
    }
  }

  .random {
    color: #b9b9b9;
  }
}
